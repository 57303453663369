import React from "react"
import SliderComponent from "./SliderComponent"
import parse, { domToReact } from "html-react-parser"

const EventHeroComponent = props => {
  const options = {
    replace: ({ attribs, name, children }) => {
      if (name === "a") {
        return (
          <a className="underline" target="_blank" rel="noopener noreferrer" href={attribs.href}>
            {domToReact(children, options)}
          </a>
        )
      }
    },
  }
  return (
    <div className="lg:grid lg:grid-cols-2 mx-3 lg:mx-7 gap-4">
      <div className="">
        <SliderComponent sliderItems={props.sliderItems}></SliderComponent>
      </div>
      <div className="my-3">
        <p className="font-futurastd_heavy uppercase text-lg ">
          {props.pageData.title}
        </p>
        <p className="font-futurastd_light uppercase text-lg ">
          {props.pageData.subtitle}
        </p>
        <div className="lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom w-full h-1"></div>
        <div className="text-xs pb-6 pt-3 font-futurastd_book">
          {parse(props.pageData.longDescription.html, options)}
        </div>
      </div>
    </div>
  )
}

export default EventHeroComponent
