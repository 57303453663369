import React from "react"
import parse, { domToReact } from "html-react-parser"

const LinksList = props => {
    const options = {
        replace: ({ attribs, name, children }) => {
          if (name === "a") {
            return (
              <a className="underline" target="_blank" rel="noopener noreferrer" href={attribs.href}>
                {domToReact(children, options)}
              </a>
            )
          }
        },
      }
    return(
        <div className="mx-3 lg:mx-7">
          <div className="font-futurastd_heavy uppercase text-lg lg:bg-title-dashed-bg lg:bg-no-repeat lg:bg-bottom">
            {" "}
            {props.title}{" "}
          </div>
          {props.list.map((item, i) => (
            <div key={i} className="text-xs pb-6 pt-3 font-futurastd_book uppercase">
              {parse(item.html, options)}
            </div>
          ))}
        </div>
    )

}

export default LinksList
